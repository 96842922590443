<template>
  <div class="laboratory-detail">
    <section class="container">
      <div class="row ptb5">
        <div id="lab-info-main-container" class="columns lab-info-main-container">

          <div class="column is-6 lab-info-wrap">
            <div class="lab-card-wrap">
              <LaboratoryCard v-if="lab !== null" :laboratory="lab" :is-big-card="true"></LaboratoryCard>
            </div>
          </div>

          <div class="column is-6 lab-info-wrap">
            <div class="lab-gallery-wrap">
              <VueSlickCarousel ref="carousel" v-bind="gallerySettings">
                <div class="gallery-image-wrap">
                  <img src="../assets/images/5-about-1-hero.jpg"/>
                </div>
                <div class="gallery-image-wrap">
                  <img src="../assets/images/5-about-1-hero.jpg"/>
                </div>
                <div class="gallery-image-wrap">
                  <img src="../assets/images/5-about-1-hero.jpg"/>
                </div>
              </VueSlickCarousel>
              <button class="nav-gallery-btn prev" @click="showPrevGallery"><span class="icon-arrow-left"></span></button>
              <button class="nav-gallery-btn next" @click="showNextGallery"><span class="icon-arrow-right"></span></button>
            </div>
          </div>
        </div>
      </div>
      <div class="row ptb5 mt-6">
        <div class="column services-list-column">
            <div class="services-list-wrap">
              <div class="services-list-head" v-if="categories !== null">
                <div class="columns search-wrap">
                  <div class="column control has-icons-right">
                    <input class="input is-rounded search-input" type="text" placeholder="Search a service (e.g. full protesis, corona implant…)" v-model="searchText" @input="searchProduct()">
                    <span class="icon is-right has-text-centered">
                      <span class="icon-search"></span>
                    </span>
                    <p class="pt-1 has-text-centered">{{products.length}} resultados encontrados</p>
                  </div>
                </div>
              </div>
              <div id="category-bar" class="services-list-head" v-bind:class="{'is-sticky': stickCategoryBar}" v-if="categories !== null">
                <!-- CATEGORY STICKY BAR -->
                <div class="columns category-wrap">
                  <template v-for="category in categoriesMenu">
                    <div :key="category.id" class="service-btn-wrap" v-if="category.products.length > 0">
                      <button @click="selectServiceCategory(category)" v-bind:class="{'selected': selectedServiceCategory.id === category.id}">
                        {{category.name}}
                      </button>
                    </div>
                  </template>
                  <div class="service-btn-wrap has-more" v-if="moreCategories.length > 0">
                    <button @click="toggleMoreCategories()" v-bind:class="{'selected': moreCategoriesIsOpen}">
                      MÁS
                    </button>
                  </div>
                  <div v-if="moreCategoriesIsOpen" class="more-categories-wrap">
                    <template v-for="category in moreCategories">
                      <div :key="category.id" class="column service-btn-wrap" v-if="category.products.length > 0">
                        <button @click="selectServiceCategory(category)" v-bind:class="{'selected': selectedServiceCategory.id === category.id}">
                          {{category.name}}
                        </button>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <!-- MOBILE HEADER -->
              <div class="services-list-head mobile" v-if="categories !== null">
                <div class="columns">
                  <div class="column service-btn-wrap">
                    <button @click="openSelectServiceCategory()">
                      {{selectedServiceCategory.category}}
                      <span class="icon icon-chevron-down" v-if="!isSelectServiceCategoryOpen"></span>
                      <span class="icon icon-chevron-up" v-if="isSelectServiceCategoryOpen"></span>
                    </button>
                  </div>
                  <transition name="fade">
                    <div class="category-select-wrap" v-if="isSelectServiceCategoryOpen">
                      <template v-for="category in categories">
                        <div :key="category.id" class="column service-btn-wrap">
                          <button @click="selectServiceCategory(category)" v-bind:class="{'selected': selectedServiceCategory.id === category.id}">
                            {{category.name}}
                            <span class="icon icon-chevron-down"></span>
                          </button>
                        </div>
                      </template>
                    </div>
                  </transition>
                </div>
              </div>
              <div class="loading-wrap" v-if="isLoading">
                <h3 class="title is-4">Loading ...</h3>
              </div>
              <div id="services-list-list" class="services-list-list" v-if="!isLoading">
                <template v-for="category in categories">
                  <div :key="category.id" :id="'services-' + category.id" class="service-category-wrap" :data-category="category.id" v-if="category.products.length > 0">
                    <h4 class="service-category-title" v-if="category.products.length > 0">
                      {{category.name}}
                    </h4>
                    <div class="is-flex">
                      <template v-for="product in category.products">
                        <div :id="'service-list-item-wrap-' + product.id" class="service-list-item-wrap" :key="category.id + '_' + product.id">
                          <div class="service-list-item">
                            <div class="top-row">
                              <div class="service-name"><p>{{product.name}}</p></div>
                              <button class="btn-description" v-if="product.productOffer.description !== null && product.productOffer.description !== ''"><span class="icon-info-1-1"></span>
                                <div class="description-product right-arrow">
                                  <p>{{product.productOffer.description}}</p>
                                </div>
                              </button>
                              <button v-if="!product.productOffer.complementary && isClinicLoggedIn" @click="addToCart(product)"><span class="icon-cart"></span></button>
                            </div>
                            <div class="description-row">
                              <p>Garantía: {{getProductInfo(product.productOffer.warranty)}}</p>
                              <p>Material: {{getProductInfo(product.productOffer.material)}}</p>
                              <p class="txt-yellow pb0" v-if="product.productOffer.has_complementaries">Incluye artículos complementarios que pueden aumentar el precio</p>
                            </div>
                            <div class="bottom-row">
                              <p>{{product.productOffer.price / 100}}€ <span>ENTREGA ESTIMADA EN {{product.productOffer.min_days_to_deliver}}-{{product.productOffer.max_days_to_deliver}} DÍAS</span></p>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
      </div>
      <div class="row ptb5">
        <hr class="bold-separator">
        <div class="columns">
          <div class="column is-8 is-offset-2 has-text-centered lab-description-wrap" v-if="lab !== null">
            <h2 class="title is-3" v-html="lab.name"></h2>
            <div class="lab-description" v-html="lab.description"></div>
          </div>
        </div>
      </div>
    </section>
    <div class="reviews-wrap" v-if="showReviews">
      <div class="reviews-list-horizontal-wrap">
        <VueSlickCarousel ref="carouselReviews" v-bind="reviewsSettings">
          <template v-for="review in reviews">
            <div :key="review.id" class="review-item">
              <div class="review-item-content">
                <div class="row">
                  <div class="columns is-flex-wrap-wrap is-justify-content-space-between">
                    <div class="column review-author">
                      <div class="author-avatar">{{review.author[0]}}</div>
                      <div class="author-name">
                        <p>{{review.author}}</p>
                      </div>
                    </div>
                    <div class="column review-date">
                      <p>{{review.date}}</p>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="columns is-flex-direction-column">
                    <div class="column review-title">
                      <h4 v-html="review.title"></h4>
                    </div>
                    <div class="column review-review">
                      <p v-html="review.review"></p>
                    </div>
                    <div class="column review-rating">
                      <div class="rating-wrap">
                        <span class="icon-star" v-bind:class="{'active': review.rating >= 1}"></span>
                        <span class="icon-star" v-bind:class="{'active': review.rating >= 2}"></span>
                        <span class="icon-star" v-bind:class="{'active': review.rating >= 3}"></span>
                        <span class="icon-star" v-bind:class="{'active': review.rating >= 4}"></span>
                        <span class="icon-star" v-bind:class="{'active': review.rating >= 5}"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </VueSlickCarousel>
        <button class="nav-review-btn prev" @click="showPrevReview"><span class="icon-arrow-left"></span></button>
        <button class="nav-review-btn next" @click="showNextReview"><span class="icon-arrow-right"></span></button>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import LaboratoryCard from '@/components/LaboratoryCard.vue'
import Footer from '@/components/Footer.vue'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import { mapGetters } from 'vuex'
import {
  ElemOffset,
  // CheckInView,
  AddClassById,
  RemoveClassById,
  HasClassById,
  IsEmptyOrNull
} from '@/utils/utils.js'

export default {
  name: 'LaboratoryDetail',
  components: {
    LaboratoryCard,
    Footer,
    VueSlickCarousel
  },
  computed: {
    ...mapGetters({
      isClinicLoggedIn: 'user/isClinicLoggedIn',
      isLoggedIn: 'user/isLoggedIn'
    })
  },
  data () {
    return {
      isLoading: true,
      stickCategoryBar: false,
      originalPositionCategoryBar: 0,
      showReviews: false,
      reviewsSettings: {
        centerMode: true,
        centerPadding: '21px',
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        speed: 500,
        arrows: false,
        dots: false,
        draggable: true,
        responsive: [
          {
            breakpoint: 1023,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 768,
            settings: {
              // centerPadding: '130px',
              slidesToShow: 1
            }
          }
        ]
      },
      gallerySettings: {
        arrows: false,
        dots: false
      },
      lab: null,
      isSelectServiceCategoryOpen: false,
      selectedServiceCategory: {
        id: null,
        category: ''
      },
      services: null,
      reviews: null,
      reviewsDummy: [
        {
          id: 1,
          author: 'Tooth Fairy',
          date: '21/03/2021',
          title: 'Perfect, 5 star job',
          review: 'Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Maecenas faucibus mollis interdum. Donec id elit non mi porta gravida at eget metus. ',
          rating: 3
        },
        {
          id: 2,
          author: 'Tooth Fairy',
          date: '21/03/2021',
          title: 'Perfect, 5 star job',
          review: 'Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Maecenas faucibus mollis interdum. Donec id elit non mi porta gravida at eget metus. ',
          rating: 4
        },
        {
          id: 3,
          author: 'Tooth Fairy',
          date: '21/03/2021',
          title: 'Perfect, 5 star job',
          review: 'Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Maecenas faucibus mollis interdum. Donec id elit non mi porta gravida at eget metus. ',
          rating: 5
        },
        {
          id: 14,
          author: 'Tooth Fairy',
          date: '21/03/2021',
          title: 'Perfect, 5 star job',
          review: 'Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Maecenas faucibus mollis interdum. Donec id elit non mi porta gravida at eget metus. ',
          rating: 4
        }
      ],
      lastServiceIndex: 0,
      // OK
      productOffers: [],
      products: [],
      materials: [],
      categories: [],
      categoriesMenu: [],
      moreCategories: [],
      // Search
      searchTimeout: null,
      searchText: '',
      searchIsLoading: false,
      moreCategoriesIsOpen: false
    }
  },
  watch: {
    'materials' (newVal) {
      if (newVal.length > 0 && this.categories.length > 0) {
        this.getProductOffersByLab()
      }
    },
    'categories' (newVal) {
      if (newVal.length > 0 && this.materials.length > 0) {
        this.getProductOffersByLab()
      }
    }
  },
  methods: {
    getProductInfo (value) {
      if (IsEmptyOrNull(value)) {
        return 'No disponibile'
      }
      return value
    },
    toggleMoreCategories () {
      this.moreCategoriesIsOpen = !this.moreCategoriesIsOpen
    },
    searchProduct () {
      const self = this
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        self.fetchProducts()
      }, 500)
      if (this.searchText.trim().length > 1) {
        this.searchIsLoading = true
      } else {
        this.searchIsLoading = false
      }
    },
    toggleAccordionBtn (serviceId) {
      if (HasClassById('service-toggle-closed-' + serviceId, 'show')) {
        RemoveClassById('service-toggle-closed-' + serviceId, 'show')
        AddClassById('service-toggle-opened-' + serviceId, 'show')
      } else {
        AddClassById('service-toggle-closed-' + serviceId, 'show')
        RemoveClassById('service-toggle-opened-' + serviceId, 'show')
      }
    },
    addToCart (product) {
      if (this.isLoggedIn) {
        this.openOrderDetail(product)
      } else {
        this.openLogInModal(product)
      }
    },
    openOrderDetail (product) {
      const modalOptions = {
        componentId: 'CompleteOrderDetails',
        title: 'Completa los detalles de la orden',
        laboratory: this.lab,
        productId: product.id,
        productName: product.name,
        productOfferId: product.productOffer.id,
        productOfferPrice: product.productOffer.price,
        patientName: '',
        patientLastName: '',
        odontologistName: '',
        shoppingCartItem: null,
        smallSize: false,
        isUpdate: false,
        isOrder: false,
        isReview: false,
        readOnly: false
      }
      this.$store.commit({
        type: 'application/SHOW_MODAL',
        modalOptions: modalOptions
      })
    },
    openLogInModal (product) {
      const self = this
      const modalOptions = {
        componentId: 'LoginRegister',
        title: 'Inicia sesión o registrate para seguir',
        smallSize: false,
        closeCb: () => {
          self.openOrderDetail(product)
        }
      }
      this.$store.commit({
        type: 'application/SHOW_MODAL',
        modalOptions: modalOptions
      })
    },
    showPrevGallery () {
      this.$refs.carousel.prev()
    },
    showNextGallery () {
      this.$refs.carousel.next()
    },
    showPrevReview () {
      this.$refs.carouselReviews.prev()
    },
    showNextReview () {
      this.$refs.carouselReviews.next()
    },
    handleScroll () {
      if (window.pageYOffset >= this.originalPositionCategoryBar) {
        this.stickCategoryBar = true
      } else {
        this.stickCategoryBar = false
      }
    },
    openSelectServiceCategory () {
      this.isSelectServiceCategoryOpen = !this.isSelectServiceCategoryOpen
    },
    selectServiceCategory (category) {
      if (this.selectedServiceCategory.id !== category.id) {
        this.selectedServiceCategory = category
      }
      var destination = document.getElementById('services-' + category.id)
      var destinationOffset = ElemOffset(destination)
      window.scroll({
        top: destinationOffset.top - 130,
        behavior: 'smooth'
      })
    },
    getProductOffersByLab () {
      const self = this
      this.$store.dispatch('lab/getProductOfferByLab', {
        labId: this.$route.params.labId,
        priceIn: '',
        minDaysToDeliverIn: '',
        maxDaysToDeliverIn: '',
        productIdIn: '',
        pageSize: 500,
        cb: (response) => {
          self.productOffers = response
          self.fetchProducts()
        },
        cbError: (error) => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error.response.data.error
            }
          })
        }
      })
    },
    initScrollAutoSelect () {
      const self = this
      const options = {
        rootMargin: '135px 0px 0px 0px',
        threshold: 0.4
      }
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // Element is visible
            const searchIndex = self.categories.findIndex((category) => category.id === parseInt(entry.target.dataset['category']))
            if (self.selectedServiceCategory.id !== self.categories[searchIndex].id) {
              self.selectedServiceCategory = self.categories[searchIndex]
            }
          }
        })
      }, options)
      for (let i = 0; i < this.categories.length; i++) {
        const targetElement = document.querySelector('#services-' + this.categories[i].id)
        if (!IsEmptyOrNull(targetElement)) {
          observer.observe(targetElement)
        }
      }
    },
    getCategories () {
      const self = this
      this.$store.dispatch('products/getProductCategories', {
        cb: (response) => {
          for (let i = 0; i < response.length; i++) {
            response[i].products = []
          }
          self.categories = response
          self.selectedServiceCategory = self.categories[0]

          try {
            setTimeout(() => {
              self.initScrollAutoSelect()
            }, 2000)
          } catch (e) {
            console.error(e)
          }
        },
        cbError: (error) => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error.response.data.error
            }
          })
        }
      })
    },
    getMaterials () {
      const self = this
      this.$store.dispatch('products/getProductMaterials', {
        cb: (response) => {
          self.materials = response
        },
        cbError: (error) => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error.response.data.error
            }
          })
        }
      })
    },
    getProductOfferByProduct (productId) {
      let productOffer = null
      for (let i = 0; i < this.productOffers.length; i++) {
        if (this.productOffers[i].product_id === productId) {
          productOffer = this.productOffers[i]
          break
        }
      }
      return productOffer
    },
    productsByCategory (categoryId) {
      const self = this
      return this.products.filter(function (product) {
        const productOffer = self.getProductOfferByProduct(product.id)
        if (productOffer !== null) {
          product.productOffer = productOffer
        }
        return product.category_id === categoryId && productOffer !== null
      })
    },
    getCategoryName (categoryId) {
      const result = this.categories.find(({ id }) => id === categoryId)
      return result.name
    },
    getMaterialName (materialId) {
      const result = this.materials.find(({ id }) => id === materialId)
      if (!IsEmptyOrNull(result)) {
        return 'MATERIAL: ' + result.name
      } else {
        return 'No material'
      }
    },
    fetchProducts () {
      const self = this
      const payload = {
        page: 1,
        pageSize: 1000,
        searchString: this.searchText,
        cb: (response) => {
          self.products = response

          for (let i = 0; i < self.products.length; i++) {
            const category = self.categories.find(({ id }) => id === self.products[i].category_id)
            self.products[i].productOffer = self.getProductOfferByProduct(self.products[i].id)
            if (self.products[i].productOffer !== null) {
              category.products.push(self.products[i])
            }
          }

          if (self.categories.length > 5) {
            let count = 0
            for (let j = 0; j < self.categories.length; j++) {
              if (count < 6) {
                if (self.categories[j].products.length > 0) {
                  self.categoriesMenu.push(self.categories[j])
                  count++
                }
              } else {
                if (self.categories[j].products.length > 0) {
                  self.moreCategories.push(self.categories.[j])
                }
              }
            }
          } else {
            self.categoriesMenu = self.categories
          }

          if (!IsEmptyOrNull(self.$route.params.productId)) {
            for (let i = 0; i < self.products.length; i++) {
              if (self.products[i].id === self.$route.params.productId) {
                // Open complete order detail
                if (self.isLoggedIn) {
                  self.openOrderDetail(self.products[i])
                } else {
                  self.openLogInModal(self.products[i])
                }
              }
            }
          }
          self.isLoading = false
        },
        cbError: (error) => {
          console.log(error)
          self.isLoading = false
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error.response.data.error
            }
          })
        }
      }
      this.$store.dispatch('products/getProducts', payload)
    }
  },
  created () {
    // USE this.$route.params.labId to GET all the lab info: GENERAL INFO, GALLERY, SERVICES, REVIEWS
    const self = this
    // let ticking = false
    // let lastKnownScrollPosition = 0
    /* setTimeout(() => {
      self.reviews = self.reviewsDummy
      self.showReviews = true
    }, 500) */

    this.$store.dispatch('lab/getLabDetail', {
      labId: this.$route.params.labId,
      cb: (response) => {
        self.lab = response
        self.$nextTick(function () {
          const categoryBar = document.getElementById('category-bar')
          self.originalPositionCategoryBar = categoryBar.offsetTop
        })
      },
      cbError: (error) => {
        self.$store.commit({
          type: 'application/ADD_FEEDBACK',
          feedback: {
            type: 'error',
            message: error.response.data.error
          }
        })
      }
    })

    this.getCategories()
    this.getMaterials()

    window.onscroll = function () {
      self.handleScroll()
    }
    /* document.addEventListener('scroll', function () {
      lastKnownScrollPosition = window.scrollY
      self.handleScroll(lastKnownScrollPosition)
      /* if (!ticking) {
        window.requestAnimationFrame(function () {
          self.handleScroll(lastKnownScrollPosition)
          ticking = false
        })
        ticking = true
      }
    }) */
  }
}
</script>

<style scoped lang="scss">
.laboratory-detail{
  width: 100%;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../assets/images/4-laboratories-background-top.jpg");
  .bold-separator{
    background-color: #57567C;
    margin-top: 36px;
  }
  .services-list-column{
    // height: 100%;
    .services-list-wrap{
      width: 100%;
      // height: 100%;
      border-radius: 4px;
      background-color: #FFF;
      box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
      .services-list-head{
        width: 100%;
        background-color: #57567C;
        border-radius: 4px 4px 0px 0px;
        box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
        &.is-sticky{
          // position: -webkit-sticky!important;
          position: fixed!important;
          top: 80px!important;
          left: 114px;
          width: calc(100% - 228px);
          max-width: 1366px;
          z-index: 9999999;
        }
        &.mobile{
          display: none;
        }
        .columns{
          &.search-wrap{
            height: 109px;
            padding: 14px 28px;
            .column{
              height: 100%;
              .icon{
                top: 13px;
                right: 15px;
                height: 34px;
                width: 34px;
                background-color: #E3914F;
                border-radius: 50%;
                line-height: 34px;
                color: #FFF;
              }
              p{
                color: #FFF;
              }
            }
          }
          &.category-wrap{
            position: relative;
            height: 66px;
            border-top: 1px solid rgba(255,255,255,.5);
            justify-content: flex-start;
            margin-left: 0;
            margin-right: 0;
            background-color: #57567C;
            transition: all .3s ease-out;
            top: 0px;
            z-index: 9999;
            .service-btn-wrap{
              // width: 160px;
              padding: 0 14px;
              cursor: pointer;
              /* &.has-more{
                padding: 0 14px;
                position: absolute;
                right: 0px;
                top: 0px;
                z-index: 999;
              } */
            }
            .more-categories-wrap{
              position: absolute;
              right: 0px;
              top: 100%;
              background-color: #57567C;
              padding: 14px;
              .service-btn-wrap{
                margin-bottom: 14px;
              }
            }
          }
          .service-btn-wrap{
            height: 100%;
            button{
              position: relative;
              display: block;
              height: 100%;
              width: 100%;
              background-color: transparent;
              border: 0px;
              color: #FFF;
              text-transform: uppercase;
              font-weight: 600;
              &:after{
                content: "";
                display: block;
                position: absolute;
                bottom: 0px;
                left: 50%;
                transform: translate(-50%, 0);
                width: 0%;
                height: 2px;
                background-color: #E3914F;
                transition: all .3s ease-out;
              }
              &:hover{
                &:after{
                  width: 10%;
                }
              }
              &.selected{
                &:after{
                  width: 50%;
                }
              }
            }
          }
        }
      }
      .loading-wrap{
        width: 100%;
        height: 300px;
        padding: 50px 35px;
      }
      .services-list-list{
        width: 100%;
        // height: calc(100% - 66px);
        overflow: auto;
        border-radius: 0px 0px 4px 4px;
        padding: 35px;
        padding-top: 11px;
        .service-category-title{
          color: #E3914F;
          font-weight: 600;
          text-transform: uppercase;
          font-size: 1.6rem;
          padding-bottom: 15px;
          padding-top: 24px;
        }
        .is-flex{
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .service-list-item-wrap{
            width: 50%;
            padding: 14px;
            // padding-left: 0px;
            margin-bottom: 7px;
            .service-list-item{
              height: 170px;
              width: 100%;
              border-radius: 4px;
              padding: 21px;
              box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
              transition: all .4s ease-out;
              &:hover{
                box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
              }
              .top-row{
                position: relative;
                width: 100%;
                height: 55px;
                display: flex;
                justify-content: space-between;
                // padding-bottom: 12px;
                .service-name{
                  width: calc(100% - 161px);
                  padding-right: 16px;
                  p{
                    font-size: 1.3rem;
                    font-weight: 600;
                  }
                }
                .btn-description{
                  position: absolute;
                  top: 50px;
                  right: 0px;
                  border: 2px solid #57567C;
                  background-color: transparent;
                  &:hover{
                    .description-product {
                      opacity: 1;
                    }
                  }
                  .description-product {
                    position: absolute;
                    right: 60px;
                    top: 50%;
                    padding: 10px;
                    max-width: 300px;
                    max-height: 500px;
                    transform: translate(0, -50%);
                    background-color: #FFF;
                    border: 1px solid rgb(87, 86, 124);
                    border-radius: 5px;
                    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
                    cursor: pointer;
                    opacity: 0;
                    transition: opacity .4s ease-out;
                    line-height: initial;
                    color: #57567C;
                    font-weight: initial;
                    font-size: .9rem;
                    &.right-arrow{
                      &:before{
                        content:'';
                        display: block;
                        position: absolute;
                        left: calc(100% - 1px);
                        top: 50%;
                        z-index: 2;
                        transform: translate(0, -50%);
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 12.5px 0 12.5px 21.7px;
                        border-color: transparent transparent transparent #FFF;
                      }
                      &:after{
                        content:'';
                        display: block;
                        position: absolute;
                        left: calc(100% + 1px);
                        top: 50%;
                        z-index: 1;
                        transform: translate(0, -50%);
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 12.5px 0 12.5px 21.7px;
                        border-color: transparent transparent transparent rgb(87, 86, 124);
                      }
                    }
                  }
                }
                button{
                  box-sizing: border-box;
                  height: 40px;
                  width: 40px;
                  line-height: 40px;
                  border: 2px solid #E3914F;
                  border-radius: 50%;
                  background-color: #E3914F;
                  color: #FFF;
                  transition: all .4s ease-out;
                  cursor: pointer;
                  font-weight: 600;
                  font-size: 1.2rem;
                  &:hover{
                    background-color: #FFF;
                    color: #E3914F;
                  }
                }
              }
              .bottom-row{
                width: 100%;
                display: flex;
                justify-content: space-between;
                p{
                  font-size: 1.4rem;
                  // font-weight: 600;
                  span{
                    font-size: 1rem;
                    margin-left: 20px;
                    font-weight: 300;
                  }
                }
                button{
                  border: 0px;
                  background-color: transparent;
                  font-size: .8rem;
                  color: #57567C;
                  .icon-chevron-down{
                    display: none;
                    &.show{
                      display: inline-block;
                    }
                  }
                  .icon-chevron-up{
                    display: none;
                    &.show{
                      display: inline-block;
                    }
                  }
                }
              }
              .description-row{
                height: 46px;
                width: 100%;
                p{
                  padding-bottom: 0px;
                  font-size: .8rem;
                  &.pb0{
                    padding-bottom: 0px;
                  }
                }
              }
              hr{
                margin: 16px 0px;
              }
            }
          }
        }
      }
    }
  }
  .lab-info-main-container{
    padding-top: 100px;
    // height: 737px;
    .lab-info-wrap {
      padding-top: 0px;
      height: 100%;
      max-height: calc(100vh - 60px);
      .lab-card-wrap{
        width: 100%;
        height: 100%;
        .laboratory-card{
          height: 100%;
        }
      }
      .lab-gallery-wrap{
        position: relative;
        width: 100%;
        img{
          border-radius: 4px;
        }
        .nav-gallery-btn{
          position: absolute;
          bottom: -14px;
          left: 50%;
          transform: translate(-50%, 0);
          background-color: #57567C;
          height: 40px;
          width: 40px;
          border-radius: 50%;
          border: 0px;
          span{
            color: #FFF;
            font-size: 1rem;
          }
          &.prev{
            left: calc(50% - 30px);
          }
          &.next{
            left: calc(50% + 30px);
          }
        }
      }
    }
  }
  .lab-description-wrap{
    padding: 36px 0px;
    .lab-description{
      h3{
        padding-bottom: 24px;
      }
      p{
        font-size: .8rem;
      }
    }
  }
  .reviews-wrap{
    text-align: center;
    width: 100%;
    margin: 28px 0px;
    margin-bottom: 60px;
    .reviews-list-horizontal-wrap{
      position: relative;
      width: 100%;
      padding-top: 0px;
      padding-bottom: 30px;
      .review-item{
        padding: 0px 21px;
        padding-bottom: 21px;
        // width: 500px;
        height: 316px;
        .review-item-content{
          position: relative;
          background-color: #FFF;
          // width: calc(500px - 64px);
          height: 100%;
          margin: 0 auto;
          border-radius: 10px;
          padding: 24px;
          box-shadow: 0 2px 8px 0 rgba(0,0,0,0.2);
          text-align: left;
          .review-author{
            display: flex;
            align-content: center;
            .author-avatar{
              height: 66px;
              width: 66px;
              background-color: #E3914F;
              border-radius: 50%;
              line-height: 66px;
              text-align: center;
              color: #F6F4EF;
              font-size: 36px;
            }
            .author-name{
              width: calc(100% - 66px);
              position: relative;
              p{
                position: absolute;
                left: 16px;
                top: 50%;
                transform: translate(0, -50%);
                width: 100%;
              }
            }
          }
          .review-date{
            text-align: right;
            line-height: 66px;
          }
          .review-title{
            h4{
              color: #57567C;
              font-size: 18px;
              font-weight: 600;
            }
          }
          .review-review{
            padding: 0px 12px;
            font-size: .9rem;
          }
          .review-rating{
            .rating-wrap{
              width: 100%;
              margin-top: 15px;
              display: flex;
              justify-content: flex-start;
              .icon-star{
                opacity: .1;
                font-size: 1.3rem;
                &.active{
                  opacity: 1;
                }
              }
            }
          }
        }
      }
      .nav-review-btn{
        position: absolute;
        bottom: -14px;
        left: 50%;
        transform: translate(-50%, 0);
        background-color: #57567C;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        border: 0px;
        span{
          color: #FFF;
          font-size: 1rem;
        }
        &.prev{
          left: calc(50% - 30px);
        }
        &.next{
          left: calc(50% + 30px);
        }
      }
    }
  }
}
@media screen and (max-width: 1120px) {
  .laboratory-detail{
    .reviews-wrap{
      .reviews-list-horizontal-wrap{
        .review-item{
          padding: 0 14px;
          padding-bottom: 21px;
        }
      }
    }
  }
}
@media screen and (min-width: 769px) {
  .laboratory-detail{
    .lab-info-main-container{
      .lab-info-wrap {
        height: 353px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .laboratory-detail{
    padding-left: 21px;
    padding-right: 21px;
    .reviews-wrap{
      .reviews-list-horizontal-wrap{
        .review-item{
          height: auto;
        }
      }
    }
    .lab-info-main-container{
      .lab-info-wrap{
        position: initial;
        max-height: initial;
        margin-bottom: 28px;
      }
      .services-list-column{
        .services-list-wrap{
          .services-list-head{
            .columns{
              display: flex;
              .service-btn-wrap{
                width: 33.3333333333%;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 510px) {
  .laboratory-detail{
    padding-left: 21px;
    padding-right: 21px;
    .lab-info-main-container{
      .lab-info-wrap{
        position: initial;
        max-height: initial;
        margin-bottom: 28px;
      }
      .services-list-column{
        .services-list-wrap{
          .services-list-head{
            display: none;
            &.mobile{
              display: block;
            }
            // position: sticky;
            .columns{
              display: block;
              .category-select-wrap{
                width: 100%;
                position: absolute;
                top: 100%;
                left: 0px;
                background-color: red;
                border-radius: 0px 0px 4px 4px;
                background-color: #57567C;
                .service-btn-wrap{
                  button{
                    height: 40px;
                    text-align: center;
                    .icon{
                      display: none;
                    }
                    &.selected{
                      &:after{
                        width: 50%;
                      }
                    }
                  }
                }
              }
              .service-btn-wrap{
                width: 100%;
                button{
                  padding: 0 24px;
                  text-align: left;
                  &.selected, &:hover{
                    &:after{
                      width: 0%;
                    }
                  }
                  .icon{
                    float: right;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
